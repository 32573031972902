export function changeNumber(val) {
    var number = val.toString().replace(/\d+/, function (n) {
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
            return $1 + ",";
        });
    })
    return number;
}
export function numberToFixed(val,num) {
    if (val.toString().indexOf('.') == -1) {
        if (num == 8) {
            return val.toString()+'.00000000'
        } else if (num == 6) {
            return val.toString()+'.000000'
        } else if (num == 4) {
            return val.toString()+'.0000'
        } else {
            return val.toString()+'.000'
        }
    } else {
        if (num == 8) {
            return val.toString().match(/^\d+(?:\.\d{0,8})?/)[0];
        } else if (num == 6) {
            return val.toString().match(/^\d+(?:\.\d{0,6})?/)[0];
        } else if (num == 4) {
            return val.toString().match(/^\d+(?:\.\d{0,4})?/)[0];
        } else {
            return val.toString().match(/^\d+(?:\.\d{0,3})?/)[0];
        }
    }
}

export function getFarmApr (starBlock,poolRatio,starPrice,allTokenPrice) {
    var BLOCKS_PER_YEAR = (60/1)*60*24*365
    const a = (starBlock * BLOCKS_PER_YEAR * poolRatio * 0.7 * 0.9 * starPrice)
    const apr = a / allTokenPrice * 100
    return apr
}
export function getOtherFarmApr (starBlock,starPrice,allTokenPrice) {
    var BLOCKS_PER_YEAR = (60/1)*60*24*365
    const a = (starBlock * BLOCKS_PER_YEAR * starPrice)
    const apr = a / allTokenPrice * 100
    return apr
}

export function timetrans(Time){  
    var _date = new Date(parseFloat(Time))
    var Y = _date.getFullYear() + '.';
    var M = (_date.getMonth()+1 < 10 ? '0'+(_date.getMonth()+1) : _date.getMonth()+1) + '.';
    var D = _date.getDate() + '';
    var h = _date.getHours() + ':';
    var m = _date.getMinutes() + ':';
    var s = _date.getSeconds();  
    if (_date.getDate() < 10 ){D = "0" + D ;}
    if (_date.getHours() < 10 ){h = "0" + h ;}
    if (_date.getMinutes() < 10 ){m = "0" + m ;}
    if (_date.getSeconds() < 10 ){s = "0" + s ;} 
    return(Y+M+D+" "+h+m+s)
    // return(Y+M+D)
};

export function timetransYMD(Time){  
    var _date = new Date(parseFloat(Time))
    var Y = _date.getFullYear() + '.';
    var M = (_date.getMonth()+1 < 10 ? '0'+(_date.getMonth()+1) : _date.getMonth()+1) + '.';
    var D = _date.getDate() + '';
    var h = _date.getHours() + ':';
    var m = _date.getMinutes() + ':';
    var s = _date.getSeconds();  
    if (_date.getDate() < 10 ){D = "0" + D ;}
    if (_date.getHours() < 10 ){h = "0" + h ;}
    if (_date.getMinutes() < 10 ){m = "0" + m ;}
    if (_date.getSeconds() < 10 ){s = "0" + s ;} 
    return(Y+M+D)
    // return(Y+M+D)
};